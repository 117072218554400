<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="전체 통계">
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <CarrotDatePicker v-model="comp.search.sdate" @change="comp.find()"></CarrotDatePicker> ~ <CarrotDatePicker @change="comp.find()" v-model="comp.search.edate"></CarrotDatePicker>

                            <table class="table-col mt-50 w-400px" v-if="comp.report!=null">
                                <colgroup>
                                    <col span="2">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>전체 경비</th>
                                        <th>전체 수당</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{comp.number_format(comp.expense.indiv.total + comp.expense.corp.total)}}</td>
                                        <td>{{comp.number_format(comp.report.total.price + comp.report.overtime.price + comp.report.extrapay.price)}}</td>
                                    </tr>
                                    <tr class="total">
                                        <td colspan="2" class="total"><span class="color-1">총 합계 {{comp.number_format(comp.expense.indiv.total + comp.expense.corp.total + comp.report.total.price + comp.report.overtime.price + comp.report.extrapay.price)}}</span></td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table-col mt-50" v-if="comp.expense!=null">
                                <colgroup>
                                    <col span="8">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colspan="4">개인 경비</th>
                                        <th colspan="4">법인 경비</th>
                                    </tr>
                                    <tr>
                                        <th>교통비</th>
                                        <th>식대</th>
                                        <th>유류비</th>
                                        <th>기타</th>
                                        <th>교통비</th>
                                        <th>식대</th>
                                        <th>유류비</th>
                                        <th>기타</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{comp.number_format(comp.expense.indiv.expense)}}</td>
                                        <td>{{comp.number_format(comp.expense.indiv.meals)}}</td>
                                        <td>{{comp.number_format(comp.expense.indiv.fuel)}}</td>
                                        <td>{{comp.number_format(comp.expense.indiv.etc)}}</td>
                                        <td>{{comp.number_format(comp.expense.corp.expense)}}</td>
                                        <td>{{comp.number_format(comp.expense.corp.meals)}}</td>
                                        <td>{{comp.number_format(comp.expense.corp.fuel)}}</td>
                                        <td>{{comp.number_format(comp.expense.corp.etc)}}</td>
                                    </tr>
                                    <tr class="total">
                                        <td colspan="4" class="total"><span class="color-1">총 합계 {{comp.number_format(comp.expense.indiv.total)}}</span></td>
                                        <td colspan="4" class="total"><span class="color-1">총 합계 {{comp.number_format(comp.expense.corp.total)}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <table class="table-col mt-50" v-if="comp.report!=null">
                                <colgroup>
                                    <col span="7">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colspan="5">외근수당</th>
                                        <th rowspan="2">야근수당</th>
                                        <th rowspan="2">추가수당</th>
                                    </tr>
                                    <tr>
                                        <th>오픈</th>
                                        <th>참관</th>
                                        <th>타지</th>
                                        <th>합숙</th>
                                        <th>합계</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{comp.number_format(comp.report.s01.price)}}</td>
                                        <td>{{comp.number_format(comp.report.s02.price)}}</td>
                                        <td>{{comp.number_format(comp.report.s03.price)}}</td>
                                        <td>{{comp.number_format(comp.report.s04.price)}}</td>
                                        <td>{{comp.number_format(comp.report.total.price)}}</td>
                                        <td>{{comp.number_format(comp.report.overtime.price)}}</td>
                                        <td>{{comp.number_format(comp.report.extrapay.price)}}</td>
                                    </tr>
                                    <tr class="total">
                                        <td colspan="7" class="total"><span class="color-1">총 합계 {{comp.number_format(comp.report.total.price + comp.report.overtime.price + comp.report.extrapay.price)}}</span></td>
                                    </tr>
                                </tbody>
                            </table>

                            <!-- <table class="table-col mt-50" v-if="comp.expense!=null">
                                <colgroup>
                                    <col span="6">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colspan="6">본부 별 경비</th>
                                    </tr>
                                </thead>
                                <tbody v-for="(lst, idx) in comp.expense.office" :key="idx">
                                    <tr>
                                        <th v-for="(itm, i) in lst" :key="i" class="th bold">{{itm.office}}</th>
                                    </tr>
                                    <tr>
                                        <td v-for="(itm, i) in lst" :key="i"><span v-if="itm.cost!==''">{{comp.number_format(itm.cost)}}</span></td>
                                    </tr>
                                </tbody>

                                <tbody>
                                    <tr class="total">
                                        <td colspan="6" class="total"><span class="color-1">총 합계 {{comp.number_format(comp.expense.office_total)}}</span></td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table-col mt-50" v-if="comp.extra!=null">
                                <colgroup>
                                    <col span="6">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colspan="6">본부 별 수당</th>
                                    </tr>
                                </thead>
                                <tbody v-for="(lst, idx) in comp.extra.office" :key="idx">
                                    <tr>
                                        <th v-for="(itm, i) in lst" :key="i" class="th bold">{{itm.office}}</th>
                                    </tr>
                                    <tr>
                                        <td v-for="(itm, i) in lst" :key="i"><span v-if="itm.cost!==''">{{comp.number_format(itm.cost)}}</span></td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr class="total">
                                        <td colspan="6" class="total"><span class="color-1">총 합계 80,000</span></td>
                                    </tr>
                                </tbody>
                            </table> -->
                          <table class="table-col mt-50">
                            <tr>
                              <td>
                                <table class="table-col mt-50 w-580px" v-if="comp.expense!=null">

                                  <thead>
                                  <tr>
                                    <th :colspan="comp.expense.sector_total">부문 별 경비</th>
                                  </tr>
                                  </thead>
                                  <tbody v-for="(lst, idx) in comp.expense.sectorList" :key="idx">
                                  <tr>
                                    <th v-for="(itm, i) in lst" :key="i" class="th">{{itm.sector}}</th>
                                  </tr>
                                  <tr>
                                    <td v-for="(itm, i) in lst" :key="i"><span v-if="itm.cost!==''">{{comp.number_format(itm.cost)}}</span></td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td>
                                <table class="table-col w-580px  ml-20" v-if="comp.extra!=null">
                                  <thead>
                                  <tr>
                                    <th :colspan="comp.extra.sector_total">부문 별 수당</th>
                                  </tr>
                                  </thead>
                                  <tbody v-for="(lst, idx) in comp.extra.sector" :key="idx">
                                  <tr>
                                    <th v-for="(itm, i) in lst" :key="i" class="th">{{itm.sector}}</th>
                                  </tr>
                                  <tr>
                                    <td v-for="(itm, i) in lst" :key="i"><span v-if="itm.cost!==''">{{comp.number_format(itm.cost)}}</span></td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                          </table>



                            <table class="table-col mt-50" v-if="comp.expense!=null">
                                <colgroup>
                                    <col span="8">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colspan="8">팀 별 경비</th>
                                    </tr>
                                </thead>
                                <tbody v-for="(lst, idx) in comp.expense.list" :key="idx">
                                    <tr>
                                        <th v-for="(itm, i) in lst" :key="i" class="th">{{itm.dept}}</th>
                                    </tr>
                                    <tr>
                                        <td v-for="(itm, i) in lst" :key="i"><span v-if="itm.cost!==''">{{comp.number_format(itm.cost)}}</span></td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table-col mt-50" v-if="comp.extra!=null">
                                <colgroup>
                                    <col span="8">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colspan="8">팀 별 수당</th>
                                    </tr>
                                </thead>
                                <tbody v-for="(lst, idx) in comp.extra.team" :key="idx">
                                    <tr>
                                        <th v-for="(itm, i) in lst" :key="i" class="th">{{itm.team}}</th>
                                    </tr>
                                    <tr>
                                        <td v-for="(itm, i) in lst" :key="i"><span v-if="itm.cost!==''">{{comp.number_format(itm.cost)}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue';
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'


export default {
    components: {
        CarrotDatePicker,
    },
    setup() {
        const toast = useToast();
        const store = useStore();
        const router = useRouter();

        const comp = reactive({
            search : {
                sdate : "",
                edate : "",
            },
            report : null,
            expense : null,
            extra : null,

            number_format: (num) => { 
                let price = num.toString().replace(/,/g, "");
                price = parseFloat(price);
                if(isNaN(price)) {
                    price = 0;
                }
                return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            toStringByFormatting : (source, delimiter = '-') => {
                 const year = source.getFullYear();
                 const month = comp.leftPad(source.getMonth() + 1);
                 const day = comp.leftPad(source.getDate());
                 return [year, month, day].join(delimiter); 
            },
            leftPad : (value) => {
                if (value >= 10) { 
                    return value; 
                } 
                return `0${value}`;
            },
            find : () => {
                let param = {params : comp.search};
                axios.get('/rest/extrapay/statistics', param).then((res)=>{
                    if( res.data.err == 0 ){
                        comp.report = res.data.report;
                        comp.expense = res.data.expense;
                        comp.extra = res.data.extra;
                        // comp.expense = res.data.list;
                        // comp.overtime = res.data.overtime;
                        // comp.extrapay = res.data.extrapay;
                        // for(let i=0;i<comp.overtime.length;i++) {
                        //     comp.total_overtime = parseInt(comp.overtime[i].cost);
                        // }
                        // for(let i=0;i<comp.extrapay.length;i++) {
                        //     comp.total_extrapay = parseInt(comp.extrapay[i].cost);
                        // }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

        });
        onMounted(() => {
            // Mounted
            if(store.state.position!='리드' || store.state.isSalesAdmin ) {
                toast.error("접근 권한이 없습니다.");
                router.push({ 
                    path:"/documents/MyExtraPayManagement"
                });
                return;
            }

            let today = new Date();
            comp.search.sdate = comp.toStringByFormatting(new Date(today.getFullYear(), today.getMonth(), 1));
            comp.search.edate = comp.toStringByFormatting(new Date(today.getFullYear(), today.getMonth()+1, 0));
            comp.find();
        });

        return {comp};
    },
}
</script>

<style lang="scss" scoped>
</style>